import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';


const Prefetch = () => {
    const { username } = useAuth();
    useEffect(() => {
    }, [username])

    return <Outlet />
}
export default Prefetch