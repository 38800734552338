import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUserProfile } from "../features/api/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import defaultImg from '../img/leadership/default.png'

import {
  faPhoneAlt,
  faEnvelope,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { updatePassword } from "../features/auth/authApiSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, isLoading, error } = useSelector((state) => state.user);

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [basicData, setBasicData] = useState({
    username: "",
    profileLink: "",
    email: "",
    phone: "",
    address: "",
  });
  const [isFormEdited, setIsFormEdited] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setBasicData({
        username: user.username || "",
        profileLink: user.profileLink || "",
        email: user.email || "",
        phone: user.phone || "",
        address: user.address || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        toast.error(error) // Reset the ref when the error is cleared
      }, 0);
    }
  }, [error]);

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;
    setBasicData((prev) => ({ ...prev, [name]: value }));
    setIsFormEdited(true);
  };

  const handleupdateUserProfile = async () => {
    try {
      const result = await dispatch(updateUserProfile(basicData)).unwrap();
      // setMessage(result); // Display success message
      
      setIsFormEdited(false); // Disable update button
      await dispatch(fetchUserProfile());
      toast.success(result.message);
    } catch (err) {
      // setMessage(err); // Display error message
      toast.error(err.message);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const result = await dispatch(updatePassword(passwordData)).unwrap();
      // setMessage(result); 
      toast.success(result.message);
      setPasswordData({ oldPassword: "", newPassword: "" }); // Clear input fields
    } catch (err) {
      // setMessage(err); // Display error message
      toast.error(err.message);
    }
  };

  if (isLoading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  // if (error) {
  //   // return <div className="container mx-auto px-4 py-8">Error: {error}</div>;
  //   setTimeout(() => {
  //     toast.error(error);
  //   }, 0);
  // }

  const currentImg = (basicData.profileLink.length !=0) ? basicData.profileLink : defaultImg;
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="flex justify-center py-6">
          <img
            src={currentImg}
            alt="Profile"
            className="rounded-full w-32 h-32 object-cover"
          />
        </div>
        <form className="px-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={basicData.username}
              onChange={handleBasicInfoChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={basicData.email}
              onChange={handleBasicInfoChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              value={basicData.phone}
              onChange={handleBasicInfoChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <input
              type="text"
              name="address"
              value={basicData.address}
              onChange={handleBasicInfoChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>

          <button
            type="button"
            onClick={handleupdateUserProfile}
            disabled={!isFormEdited}
            className={`w-full py-2 px-4 rounded-md text-white mt-4 ${
              isFormEdited
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            Update
          </button>

          <ToastContainer position="top-right" autoClose={2000} hideProgressBar />
        </form>
      </div>
    </div>
  );
};

export default Profile;
