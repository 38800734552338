import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refreshUserToken } from './authApiSlice';

const PersistLogin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUserToken()); // Dispatch refresh action on component mount
  }, [dispatch]);

  return <div>Persist Login</div>;
};

export default PersistLogin;
