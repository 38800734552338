import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Public from './components/Public';
import Login from './features/auth/Login';
import Register from './features/auth/Register';
import Prefetch from './features/auth/Prefetch';
import PersistLogin from './features/auth/PersistLogin';
import PrivateRoute from './features/auth/PrivateRoute'; // New PrivateRoute HOC
import useTitle from './hooks/useTitle';
import './index.css';

// Other components
import About from './components/About';
import Team from './components/Team';
import Projects from './components/Projects';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Donate from './components/Donate';
import Approach from './components/Approach';
import GeoPresence from './components/GeoPresence';
import Leadership from './components/Leadership';
import Awards from './components/Awards';
import MediaCoverage from './components/MediaCoverage';
import Profile from './components/Profile';
import TermsAndConditions  from './components/TermsAndConditions';

function App() {
  useTitle('APS Social Welfare and Youth Empowerment Foundation');

  return (
    <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
      <Route path="/" element={<Layout />}>
        {/* Public routes */}
        <Route index element={<Public />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/approach" element={<Approach />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/awards" element={<Awards />} />
        
        <Route path="/presence" element={<GeoPresence />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/terms" element={<TermsAndConditions />} />

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<Prefetch />}>
          
            <Route path="/media" element={<MediaCoverage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
