import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faChevronDown,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../img/logo.png";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logoutUser } from "../features/auth/authApiSlice"; // import the logout action
import useAuth from "../hooks/useAuth";
import { logout } from "../features/auth/authSlice";
import defaultImage from "../img/leadership/default.png"

const Header = () => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [user, setUser] = useState(null); // State to store user data
  const navigate = useNavigate();
  const { username, id } = useAuth();
  useEffect(() => {
    window.scrollTo(0, 0);

    // Simulated user check (replace this logic with real authentication state retrieval)
    // const loggedInUser = JSON.parse(localStorage.getItem('user'));
    console.log("this= ", username);
    if (username) {
      setIsLoggedIn(true);
      setUser(username);
    }
  }, [username]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser()); // Dispatch the logout action
    localStorage.removeItem("user"); // Clear user data
    setIsLoggedIn(false);
    setUser(null);
    navigate("/login"); // Redirect to login page
  };

  const socialLinks = [
    { href: "https://www.facebook.com", icon: faFacebookF, label: "Facebook" },
    {
      href: "https://www.instagram.com",
      icon: faInstagram,
      label: "Instagram",
    },
    { href: "https://www.linkedin.com", icon: faLinkedinIn, label: "LinkedIn" },
    { href: "https://www.youtube.com", icon: faYoutube, label: "YouTube" },
    { href: "https://www.whatsapp.com", icon: faWhatsapp, label: "WhatsApp" },
  ];

  return (
    <>
      {/* Top Bar */}
      <div className="fixed bg-cyan-950 py-2 w-full z-40 top-0">
        <div className="container mx-auto px-4 flex flex-wrap justify-between items-center text-white">
          {/* Phone and Email Section */}
          <div className="flex space-x-4 font-semibold mb-2 md:mb-0">
            <a
              href="tel:+917065960001"
              className="text-white flex items-center"
            >
              <FontAwesomeIcon icon={faPhone} className="text-green-600" />
              <p className="hidden md:inline ml-1">91-7065960001</p>
            </a>
            <a
              href="mailto:info@apsswyefoundation.in"
              className="text-white flex items-center"
            >
              <FontAwesomeIcon icon={faEnvelope} className="text-green-600" />
              <p className="hidden md:inline ml-1">
                apsfoundation2021@gmail.com
              </p>
            </a>
          </div>

          {/* Social Media Icons and Login/Profile Button */}
          <div className="flex items-center space-x-4">
            {socialLinks.map(({ href, icon, label }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-green-700"
                aria-label={label}
              >
                <FontAwesomeIcon icon={icon} size="lg" />
              </a>
            ))}
            {isLoggedIn ? (
              <div className="relative">
                <button
                  onClick={toggleUserDropdown}
                  className="w-8 h-8 flex justify-center items-center text-white font-semibold rounded-full bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-700"
                >
                  {/* Optional: Add content inside the button */}
                  <img
                    src={user.profilePic || defaultImage}
                    alt="Profile"
                    className="w-8 h-8 rounded-full"
                  />
                </button>
                {isUserDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-md z-50">
                    <Link
                      to="/profile"
                      onClick={toggleUserDropdown}
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100 transition-all duration-200"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-100 transition-all duration-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="bg-green-700 text-white font-semibold px-4 py-1 rounded hover:bg-green-800"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Logo and Navigation */}
      <div className="border-b py-1 shadow-md fixed w-full z-30 top-12 bg-white">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <a href="/" className="flex items-center">
            <img src={logo} alt="Logo" className="h-24 mr-4" />
            <h1 className="text-2xl font-bold hidden md:block">
              APS SOCIAL WELFARE AND YOUTH EMPOWERMENT FOUNDATION
            </h1>
          </a>

          {/* Hamburger Icon for Mobile */}
          <button
            onClick={toggleMobileMenu}
            className="p-2 text-green-700 md:hidden"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} size="2xl" />
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:w-3/4">
            <nav className="flex justify-end space-x-6 font-semibold font-sans">
              <Link to="/" className="px-4 py-2 hover:text-green-700">
                Home
              </Link>
              <Link to="/about" className="px-4 py-2 hover:text-green-700">
                About Us
              </Link>
              <Link to="/projects" className="px-4 py-2 hover:text-green-700">
                Projects
              </Link>
              <Link to="/gallery" className="px-4 py-2 hover:text-green-700">
                Gallery
              </Link>
              <Link to="/contact" className="px-4 py-2 hover:text-green-700">
                Contact Us
              </Link>
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="px-4 py-2 hover:text-green-700 flex items-center"
                >
                  More about us{" "}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="ml-2 text-sm"
                  />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-50">
                    <Link
                      to="/awards"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Awards
                    </Link>
                    <Link
                      to="/team"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Our Team
                    </Link>
                    <Link
                      to="/approach"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Our Approach
                    </Link>
                    <Link
                      to="/leadership"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Our Leadership
                    </Link>
                    <Link
                      to="/media"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Media Coverage
                    </Link>
                    <Link
                      to="/presence"
                      className="block px-4 py-2 text-gray-700 hover:bg-green-100"
                    >
                      Geographical Presence
                    </Link>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
