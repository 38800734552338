// src/features/auth/authApiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setCredentials, logOut } from './authSlice';
const port = process.env.PORT || 3500;
const url = `http://localhost:${port}`;

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token found');
      }
      const response = await fetch(`${url}/auth/update-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': `Bearer ${token}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Password update failed');
      }
      return data.message;
    } catch (error) {
      return rejectWithValue(error.message || 'Password update failed');
    }
  }
);

// Thunk for login action
export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      console.log(`${url}/auth/login`);
      const response = await fetch(`${url}/auth/login`, {
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        throw new Error(data.message || 'Invalid login credentials');
      }
      localStorage.setItem('token', data.token); // Save token in localStorage
      dispatch(setCredentials({ accessToken: data.token }));
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'Login failed');
    }
  }
);

// Thunk for register action
export const registerUser = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${url}/auth/register`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      console.log("ui ",data)
      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }
      return data;
    } catch (error) {
      console.log("Dui ",error)
      return rejectWithValue(error.message || 'Registration failed');
    }
  }
);

// Thunk for logout action
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    localStorage.removeItem('token'); // Remove token from localStorage
    dispatch(logOut()); // Dispatch logout to update state
  }
);
// user info thunk
export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return rejectWithValue('No token found');
    }

    try {
      const response = await fetch(`${url}/auth/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token, // Passing token in headers
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch user');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch user');
    }
  }
);


// Thunk for refresh token action
export const refreshUserToken = createAsyncThunk(
  'auth/refresh',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${url}/auth/refresh`, {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to refresh token');
      }
      localStorage.setItem('token', data.token); // Save new token
      dispatch(setCredentials({ accessToken: data.token }));
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'Token refresh failed');
    }
  }
);

const authApiSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login action
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.token;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Login failed!';
      })

      // Register action
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Registration failed!';
      })

      // Logout action
      .addCase(logoutUser.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.token = null;
      })

      // Refresh token action
      .addCase(refreshUserToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshUserToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.token;
      })
      .addCase(refreshUserToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to refresh token';
      });
  },
});


export default authApiSlice.reducer;
