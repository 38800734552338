import React, { useState, useEffect } from 'react';
import img1 from '../img/donors/img1.png';
import img2 from '../img/donors/img2.png';
import img3 from '../img/donors/img3.png';

const donorImages = [
  { src: img1, text: 'Armaan' },
  { src: img2, text: 'Rotary Club' },
  { src: img3, text: 'Bharat Vikas Parishad' },
];

const DonorsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change the current image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % donorImages.length);
    }, 5000); // Change image every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (

        <div className='flex flex-col w-3/5'>

      <div className=" items-center border-2 border-dashed bg-cyan-950 rounded-md px-2 mb-2  ">
        <h2 className="text-2xl font-semibold text-white text-center">Our Donors</h2>
        <img
          src={donorImages[currentIndex].src}
          alt={`Donor ${currentIndex + 1}`}
          className="w-[200px] h-[200px] rounded-md shadow-lg mx-auto"
        />
        <p className="text-lg text-gray-100 text-center">{donorImages[currentIndex].text}</p>
      </div>
      </div>
  );
};

export default DonorsCarousel;
