import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const port = process.env.PORT || 3500;

// Thunk for fetching user profile
export const fetchUserProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`http://localhost:${port}/auth/user`, {
        method: 'GET',
        headers: {
          'token': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log(data)
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch user profile');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'Fetch failed');
    }
  }
);

// Thunk for updating user profile
export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async (updatedData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`http://localhost:${port}/auth/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update user profile');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'Update failed');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: '',
    isLoading: false,
    error: null,
    successMessage: null, // To store success messages
  },
  reducers: {
    clearMessages: (state) => {
      state.successMessage = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch user profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to load profile';
      })
      // Update user profile
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload; // Update the user data in state
        state.successMessage = 'Profile updated successfully';
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to update profile';
      });
  },
});

export const { clearMessages } = userSlice.actions;
export default userSlice.reducer;
