// src/features/auth/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../features/auth/authApiSlice'; // Redux slice import
import { setCredentials } from '../../features/auth/authSlice'; // Action to set credentials

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Dispatch the loginUser action from Redux
            const response = await dispatch(loginUser(formData)).unwrap(); // Use unwrap to handle the result
            // Once login is successful, navigate to the dashboard
            if (response.token) {
                dispatch(setCredentials({ accessToken: response.token }));
                navigate('/'); // Redirect to dashboard on success
            }
        } catch (err) {
            setError(err || 'Login failed!'); // Display error message
        }
    };

    const handleRegisterRedirect = () => {
        navigate('/register'); // Navigate to the registration page
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Login</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                            Username
                        </label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md text-sm font-medium shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Login
                    </button>
                </form>
                {error && (
                    <p className="mt-4 text-sm text-red-600 text-center">
                        {error}
                    </p>
                )}
                <div className="mt-4 text-center">
                    <button
                        onClick={handleRegisterRedirect}
                        className="text-blue-600 hover:text-blue-800 text-sm"
                    >
                        Don't have an account? Register here
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
