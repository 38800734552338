import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../features/auth/authSlice';
import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react';

const useAuth = () => {
    const token = useSelector(selectCurrentToken);

    const user = useMemo(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);
                console.log(decoded)
                const { username, id } = decoded;
                console.log(username, " ", id)
                return { username, id };
            } catch (error) {
                console.error("Failed to decode token:", error);
                return { username: '', id: '' };
            }
        }
        return { username: '', id: '' };
    }, [token]);

    return user;
};

export default useAuth;
