// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';  // Import your reducer
// import { userApiSlice } from '../features/api/userApiSlice';
import userReducer from '../features/api/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer
  }
}
);
