import React from 'react';

const TermsAndPrivacy = () => {
    return (
        <div className="container mx-auto px-4 py-12">
            <div className="max-w-4xl mx-auto grid gap-8">

                {/* Terms and Conditions Card */}
                <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
                    <h2 className="text-3xl font-bold mb-4">Terms and Conditions</h2>
                    <p className="mb-6 text-gray-700">By using this website, you agree to comply with the following terms and conditions.</p>
                    <ul className="list-disc pl-6 space-y-4">
                        <li><strong>Usage Agreement:</strong> This website is intended for informational purposes only. Misuse of the website or its content is strictly prohibited.</li>
                        <li><strong>Intellectual Property:</strong> All content, including images, logos, and text, is the property of APS Foundation and should not be copied without permission.</li>
                        <li><strong>Accountability:</strong> Users are responsible for ensuring their actions align with these terms and are held accountable for any misuse.</li>
                    </ul>
                </div>

                {/* Refund Policy Card */}
                <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
                    <h2 className="text-3xl font-bold mb-4">Refund Policy</h2>
                    <p className="mb-6 text-gray-700">We ensure all donations are used for the intended purpose. Please review our refund policies below:</p>
                    <ul className="list-disc pl-6 space-y-4">
                        <li><strong>No Refund on Donations:</strong> Donations made to APS Foundation are non-refundable unless there was a transaction error.</li>
                        <li><strong>Transaction Errors:</strong> If you face any technical error during donation, please contact us at <a href="mailto:apsfoundation2021@gmail.com" className="text-green-600 font-semibold">apsfoundation2021@gmail.com</a>.</li>
                        <li><strong>Incorrect Amount:</strong> If an incorrect donation amount was charged, please reach out to us within 7 days of the transaction.</li>
                    </ul>
                </div>

                {/* Privacy Policy Card */}
                <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
                    <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
                    <p className="mb-6 text-gray-700">We are committed to protecting your privacy and ensuring data security.</p>
                    <ul className="list-disc pl-6 space-y-4">
                        <li><strong>Data Collection:</strong> We collect personal information such as name, contact details, and payment information solely for donation purposes.</li>
                        <li><strong>Data Usage:</strong> Your data will be used for donation tracking, issuing receipts, and keeping you informed about our initiatives.</li>
                        <li><strong>Data Protection:</strong> We employ secure encryption methods to ensure your personal data is protected against unauthorized access.</li>
                        <li><strong>Third-Party Sharing:</strong> Your personal data will not be shared with third parties unless legally required.</li>
                        <li><strong>Cookies Policy:</strong> This site uses cookies to enhance user experience and website analytics.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TermsAndPrivacy;
